export const translations: any = {
    en: {
        workingHourMessageHeader: "We regret that we are unable to serve you at this time",
        sign_lang_label: "I am using sign language",
        sign_lang_val_yes: "Yes",
        sign_lang_val_no: "No",
        name: "Name",
        name_placeholder: "Name",
        mobile: "Mobile Number",
        mobile_placeholder: "Mobile Number",
        email: "Email Address",
        email_placeholder: "Email Address",
        nin_residence: "Passport / GCC ID ",
        nin: "National ID / Iqama ID",
        nin_residence_placeholder: "Passport / GCC ID ",
        validation_length_nin: "Invalid Passport / GCC ID",
        nin_placeholder: "Number must be 10 digits",
        entity: "ُEntity",
        investor: "National ID / Iqama ID",
        visitor: "Passport / GCC ID",
        service_type: "Document Type",
        start_from_here: "Start Here",
        confirmation_approval: "Please Approve the Confirmation",
        required_field: "Required Field",
        submit_btn: "Continue",
        save_btn: "Submit",
        confirmation_text: "I, as a user of this service, hereby pledge not to record this call and to maintain the confidentiality of the information discussed, in compliance with all provisions and regulations outlined in the Anti-Cybercrime Bylaw issued by Royal Decree No. M/<d style=\"font-family:'tahoma !important'\">17</d> dated <d style=\"font-family:'tahoma !important'\">08/03/1428</d>, and all relevant laws and regulations",
        record_text: "I pledge my commitment to the proper appearance, uniform, and place before and during the use of the service. and if otherwise, the call will be terminated, and the relevant regulations and instructions applied.",
        recordMessage: "In accordance with the Communications and Information Technology System, issued by the Council of Ministers' Decision No. (<d style=\"font-family:'tahoma !important'\">592</d>) on the date <d style=\"font-family:'tahoma !important'\">1/11/1443</d> AH, and its executive regulation regarding the protection of personal data of users in the telecommunications, space, and technology sectors, the calls is recorded for the purpose of improving the quality of the services provided. These recordings are protected and used only for training our employees and ensuring the provision of outstanding service. We guarantee the confidentiality and privacy of the information contained in these calls in accordance with the applicable laws and regulations",
        recordBold: "Disclaimer",
        validation_invalid_nin: "Invalid NIN",
        validation_invalid_mobile: "Phone number must be 10 digits",
        validation_only_digits: "Only Digits are allowed",
        validation_name: "Please enter a valid name",
        validation_email: "Email is incorrect",
        our_branch: "SBC Virtual Assistant (Beta Launch)",
        application_name: "Endak",
        message: "This service facilitates conducting a virtual visit, and communicating with customer service representatives through video calls conveniently and easily",
        otp_header: "Please insert otp",
        otp_label: "otp code",
        resend_otp_btn: "Resend",
        retry_btn: "Retry",
        retry_btn_after: "Retry After",
        for_better_service: "For Better service",
        activate_mic_camera: "Please activate Camera and MIC",
        mic: "Microphone",
        camera: "Camera",
        start_call_btn: "Start Call",
        waiting_message_1: "Our agents working with other customers",
        waiting_message_2: "Please do not close the page",
        in_queue: "In Queue",
        exit_btn: "Exit",

        rating_message: "We hope that you will participate in this survey that is looking for a reason for your satisfaction and benefit from your comments that will contribute effectively to the work team in this post.",
        rating_q1: "What is your satisfaction about provided service ?",
        rating_q2: "What is your satisfaction about employee ? ",
        rating_q3: "What is your satisfaction about  digital human ?",
        fully_satisfied: "Fully Satisfied",
        satisfied: "Satisfied",
        rating_equal: "Equal",
        rating_unsatisfies: "Unsatisfied",
        fully_unsatisfied: "Fully Unsatisfied",
        unsatisfaction_reason: "Unsatisfaction Reason ? ",
        notes_comments: "Notes ",
        after_rating_message_1: "Thank you for rating",
        after_rating_message_2: "Your satisfaction is our priority and we look forward to serving you better.",
        other: "other",
        technical_error: "technical error",
        waiting_time_is_long: "Waiting time is too long",
        exit_reason: "Exit Reason ?",
        service_rating_is_required: "Service rating is required",
        employee_rating_is_required: "Employee rating is required",
        digital_human_rating_is_required: "Digital human rating is required",
        unsatisfaction_reason_is_required: "Please insert unsatisfaction reason",
        abnormal_exit_reason_is_required: "Please insert exit reason",

        entity_option_1: "Ministry of Commerce ",
        entity_option_2: "Ministry of Municipal Rural Affairs & Housing ",
        entity_option_3: "Human Resource and Social Development ",
        entity_option_4: "Ministry of Investment ",
        entity_option_5: "General Organization for Social insurance ",
        entity_option_6: "Federation of Saudi Chamber ",
        entity_option_7: "Zakat, Tax and customs Authority",
        entity_option_8: "Ministry Of Education ",
        entity_option_9: "Saudi Business Center(Universal employee)",
        entity_option_10: "Ministry of Tourism",
        entity_option_11: "Compatibility",
        entity_option_12: "Verification",
        entity_option_13: "Ministry of Commerce - Sign Language",
        commercial_record: "Commercial Registration Number",
        commercial_record_placeholder: "Commercial Registration Number",
        service_language: "Preferred Language",
        arabic: "Arabic",
        english: "English  ",
        select: "Select",
        waitMsgL0: "Please wait while we forward the call to our representative  <br/> Please don’t refresh the page ",
        waitMsgL1: "Please wait, your call will be forwarded shortly to our representative",
        waitMsgL2: "a customer service agent will assist you soon....",
        waitMsgL3: "Waiting number:",
        waitMsgL4: "customers ",
        digit10Msg: "Ten Digits must be entered",
        chat_btn1: "Submit",
        thanksMsgL1: "Thank you for participating with us to enhance our services.",
        thanksMsgL2: "",
        chatPlaceholder: "Type here…",

        confirmationNafath: "Verification via Nafath",
        verificationRejected: "Please try again",
        underVerification: "Under verification",
        openNafath: "Please open the Nafath application and verify the code above",
        confirmInvestigation: "Verify Code",
        closeBtn: "Close",
        updateVerification: "You can update the verification by confirming again",
        errorApis: "There is an error, please try again",
        nafathErrorApiMessg: "There is an error, please ask for help",
        maxTriesNumberMessg: "Please choose the correct verification code",
        sign_in_with_nafath: "Verify your identity through Nafath National Information Center",
        please_try_again: "Please try again",
        please_open_nafath_and_confirm: "Please open the Nafath app and confirm the request by selecting the above number.",
        dont_refresh_text: "Please don't refresh the page"
    },
    ar: {
        workingHourMessageHeader: "  نأسف لعدم القدرة على خدمتكم في هذا الوقت   ",
        sign_lang_label: "انا أتحدث لغة الاشارة ",
        sign_lang_val_yes: "نعم",
        sign_lang_val_no: "لا",
        name: "الاسم",
        name_placeholder: "الاسم",
        mobile: "رقم الجوال",
        mobile_placeholder: "رقم الجوال",
        email: "الإيميل",
        email_placeholder: "الإيميل",
        nin_residence: "جواز السفر / الهوية الخليجية",
        nin: "الهوية الوطنية / الإقامة",
        nin_residence_placeholder: "جواز السفر / الهوية الخليجية",
        nin_placeholder: "الرقم مكون من 10 خانات",
        entity: "الجهة",
        validation_length_nin: "رقم جواز السفر / الهوية الخليجية غير صحيح",
        investor: "الهوية الوطنية / الإقامة",
        visitor: "جواز السفر / الهوية الخليجية",
        service_type: "نوع الوثيقة",
        start_from_here: "ابدأ من هنا",
        confirmation_approval: "الرجاء الموافقه على الإقرار ",
        required_field: "هذا الحقل مطلوب",
        submit_btn: "استمرار",
        retry_btn: "إعادة المحاولة",
        retry_btn_after: " إعادة المحاولة  بعد ",
        save_btn: "ارسال",
        confirmation_text: " أتعهد انا مستخدم هذه الخدمة بعدم تسجيل هذه المكالمة وأن احافظ على سرية البيانات الواردة فيها التزاماً بكافة الأحكام والقواعد الواردة في نظام مكافحة الجرائم المعلوماتية الصادر بالمرسوم الملكي رقم م/17 وتاريخ 1428/03/08, وبجميع الأنظمة ذات الصلة ",
        record_text: "أتعهد بالالتزام بالمظهر اللائق والزي الرسمي والمكان المناسب قبل وأثناء الاستفادة من الخدمة، وفي حال مخالفة ذلك سيتم  إنهاء الاتصال وتطبيق الأنظمة والتعليمات ذات الصلة ",
        validation_invalid_nin: "رقم الهوية غير صحيح",
        validation_invalid_mobile: "يجب أن يكون رقم الجوال من 10 أرقام",
        validation_only_digits: " غير مسموح باستخدام الحروف",
        validation_name: "من فضلك أدخل اسمًا صحيحًا",
        validation_email: "الإيميل غير صحيح",
        our_branch: "الاتصال المرئي (إطلاق تجريبي)",
        application_name: "عندك",
        message: "تتيح هذه الخدمة امكانية عقد  زيارة افتراضية  والتواصل مع موظف خدمة العملاء عبر الاتصال المرئى بكل سهولة ويسر",
        recordBold: "ملاحظة",
        recordMessage: "اتباعًا لنظام الاتصالات وتقنية المعلومات الصادر بقرار مجلس الوزراء رقم (592) بتاريخ 1443/11/1هـ ولائحته التنفيذية اتجاه حماية البيانات الشخصية للمستخدمين في قطاع الاتصالات والفضاء والتقنية؛ سنقوم بتسجيل المكالمات الهاتفية لأغراض تحسين جودة الخدمات المقدمة. هذه التسجيلات محمية وتستخدم فقط لتدريب موظفينا وضمان تقديم خدمة متميزة، ونضمن الحفاظ على سرية وخصوصية المعلومات الواردة في هذه المكالمات وفقًا للقوانين والأنظمة المتبعة",
        otp_header: "أدخل رمز التحقق المرسل على رقم الجوال المسجل في ابشر",
        otp_label: "رمز التحقق",
        resend_otp_btn: "اعادة ارسال رمز التحقق",
        for_better_service: "لخدمتك بشكل أفضل ",
        activate_mic_camera: "يرجى تفعيل جهاز الكاميرا والصوت",
        mic: "جهاز الصوت",
        camera: "جهاز الكاميرا",
        start_call_btn: "بدء الاتصال",
        waiting_message_1: "موظفينا فى خدمة عملاء اخرين وسيتم تحويلك فى أسرع وقت ممكن",
        waiting_message_2: "الرجاء عدم تحديث أو اغلاق الصفحة",
        in_queue: "أمامك فى الانتظار",
        exit_btn: "خروج",

        rating_message: "نأمل منك المشاركة فى هذا الاستبيان الذى يهدف إلى قياس رضاك والاستفادة من ملاحظاتك التى ستساهم بشكل فعّال فى فريق العمل لتحسين تجربتك",
        rating_q1: " ما مدى رضاك عن جودة الخدمة المقدمة ؟",
        rating_q2: "ما مدى رضاك عن جودة الخدمة المقدمة من الموظف ؟",
        rating_q3: "ما مدى رضاك العام عن خدمة الانسان الرقمي ؟",
        fully_satisfied: "راضى تماما",
        satisfied: "راضى",
        rating_equal: "محايد",
        rating_unsatisfies: "غير راضى",
        fully_unsatisfied: "غير راضى تماما",
        unsatisfaction_reason: "فضلا اذكر سبب عدم رضاك عن الخدمة",
        notes_comments: "ملاحظات واقتراحات",
        after_rating_message_1: "شكرا لمشاركتكم",
        after_rating_message_2: "رضاكم محل اهتمامنا ونتطلع لخدمتكم بشكل افضل",
        other: "أخرى",
        technical_error: "مشكلة تقنية",
        waiting_time_is_long: "وقت الانتظار طويل",
        exit_reason: "فضلا اذكر سبب خروجك من الانتظار",
        service_rating_is_required: "برجاء تقييم الخدمة",
        employee_rating_is_required: "برجاء تقييم الموظف",
        digital_human_rating_is_required: "رجاء تقييم الانسان الرقمى",
        unsatisfaction_reason_is_required: "الرجاء ذكر عدم رضاك عن الخدمة",
        abnormal_exit_reason_is_required: "الرجاء ذكر سبب الخروج من الانتظار",

        entity_option_1: "وزارة التجارة",
        entity_option_2: "وزارة البلديات والإسكان",
        entity_option_3: "وزارة الموارد البشرية والتنمية الاجتماعية",
        entity_option_4: "وزارة الاستثمار",
        entity_option_5: "المؤسسة العامة للتأمينات الاجتماعية",
        entity_option_6: "اتحاد الغرف السعودية",
        entity_option_7: "هيئة الزكاة والضريبة والجمارك",
        entity_option_8: "وزارة التعليم",
        entity_option_9: "المركز السعودى للأعمال (الموظف الشامل)",
        entity_option_10: "وزارة السياحة",
        entity_option_11: "الموائمة",
        entity_option_12: "التوثيق",   
        entity_option_13: "  وزارة التجارة – لغة الإشارة     ",   


        commercial_record: "السجل التجاري",
        commercial_record_placeholder: "السجل التجاري",
        service_language: "لغة التواصل",
        arabic: "اللغة العربية",
        english: "اللغة الانجليزية",
        select: "اختر",     
        waitMsgL0: "يرجى الانتظار سيتم تحويل المكالمة إلى أحد ممثلي خدمة العملاء     <br/>      يرجى عدم تحديث الصفحة ",
        waitMsgL1: "يرجى الانتظار سيتم خدمتك في أقرب وقت",
        waitMsgL2: "ممثل خدمة العملاء فى خدمة عملاء اخرين يرجى الانتظار ... ",
        waitMsgL3: "عدد الانتظار:",
        waitMsgL4: "عملاء ",
        digit10Msg: "يجب إدخال 10 أرقام",
        chat_btn1: "ارسال",
        thanksMsgL1: "شكرا لمشاركتكم",
        thanksMsgL2: "شكرا لإسهامكم معنا في تطوير خدماتنا ",
        chatPlaceholder: "اكتب هنا...",


        confirmationNafath: "التحقق عن طريق نفاذ",
        verificationRejected: "الرجاء المحاولة مرة أخرى",
        underVerification: "قيد التحقق",
        openNafath: " فضلًا ادخل تطبيق نفاذ للتحقق من الرقم أعلاه",
        confirmInvestigation: " تحقق",
        closeBtn: "الغاء",
        updateVerification: " يمكنك تحديث التحقق من خلال التأكيد مرة أخرى",
        errorApis: "يوجد عطل برجاء المحاولة مرة أخرى",
        nafathErrorApiMessg: "يوجد خطأ يرجى طلب المساعدة",
        maxTriesNumberMessg: "انتهى الوقت. الرجاء المحاولة مرة أخرى",
        sign_in_with_nafath: "الدخول عبر النفاذ الوطني الموحد",
        please_try_again: "يرجى المحاولة مرة أخرى",
        please_open_nafath_and_confirm: "الرجاء فتح تطبيق نفاذ وتأكيد الطلب بأختيار الرقم أعلاه",
        dont_refresh_text: "الرجاء عدم تحديث الصفحة"
    },
};






document.addEventListener("DOMContentLoaded", () => {


    let language = localStorage.getItem("lang")

    if (language == null) {
        language = "ar";
    }

    setLanguage(language);
});

const setLanguage = (language: any) => {
//dd

    if (language == null) {
        return;
    }
    const languageSelector = document.getElementById("languageSelector") as HTMLInputElement;
    languageSelector.value = language;
    localStorage.setItem("lang", language);

    document.dir = language === "ar" ? "rtl" : "ltr";

    const elements = document.querySelectorAll("[data-i18n]");



    elements.forEach((element: any) => {
        const translationKey = element.getAttribute("data-i18n");
        if (element.tagName.toLowerCase() != 'textarea') {
            element.textContent = translations[language][translationKey];
            element.innerHTML = translations[language][translationKey];
        }
        element.placeholder = translations[language][translationKey];
    });

    let logoBox = document.getElementById("internalLogo") as HTMLDivElement;
    let logoBoxEN = document.getElementById("internalLogoEN") as HTMLDivElement;
    if (language == "ar") {
        logoBox.style.display = "block";
        logoBoxEN.style.display = "none";
    }
    else {
        logoBoxEN.style.display = "block";
        logoBox.style.display = "none";
    }

    let content_container = document.getElementById("content-container");
    let admin_messageAR = document.getElementById("admin_message");
    let admin_messageEN = document.getElementById("admin_messageEN");

    if (admin_messageEN !== null && admin_messageAR !== null) {
        if (language === "ar") {
            admin_messageEN.style.display = "none";
            admin_messageAR.style.display = "";
        }
        else {
            admin_messageEN.style.display = "";
            admin_messageAR.style.display = "none";


        }
    }
    let languageBar = document.getElementById("languageBar");
    if (content_container != null) {
        content_container.style.direction = language === "ar" ? "rtl" : "ltr";
    }
    if (languageBar != null) {
        languageBar.style.direction = language === "ar" ? "rtl" : "ltr";
    }




    if (language === "ar") {
        document.body.style.textAlign = "right";
        document.body.style.fontFamily = "GE SS Two";

    } else {
        document.body.style.textAlign = "left";
        document.body.style.removeProperty("font-family");
        document.body.style.fontFamily = "";

    }
};

export default setLanguage;