import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import appconfig from "../appconfig.json";
import {Provider} from 'react-redux';
import {store,persistedStore} from "App";
import { PersistGate } from "redux-persist/integration/react";
import {translations} from 'translation'; 



class AbnoramRating extends Component {

  constructor(public props: any) {
    super(props);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleOtherReasonChange = this.handleOtherReasonChange.bind(this);
    this.handleWaitingReasonChange = this.handleWaitingReasonChange.bind(this);
    this.handleTechnicalReasonChange = this.handleTechnicalReasonChange.bind(this);
  }

  common = store.getState().common;

  state = {
    rating: "",
    comments: "",
    otherReason: "",
    waitingReason: "",
    technicalReason: ""

  }

  handleCommentChange(event: any) {

    this.setState({
      comments: event.target.value
    });
  }

 

    queryParams = new URLSearchParams(window.location.search);
     isPublic = this.queryParams.get("isPublic");


  saveUserFeedback = () => {
    const languageSelector = document.getElementById("languageSelector") as HTMLInputElement;
    let language = languageSelector.value;
      if(this.state.waitingReason == "" && this.state.technicalReason == "" && this.state.otherReason == ""){
        //alert("الرجاء ذكر سبب الخروج من الانتظار");
        alert(translations[language]["abnormal_exit_reason_is_required"]);
        return ;
      }


      let reason = "";
      if(this.state.waitingReason != ""){
        reason += this.state.waitingReason + ","
      }

      if(this.state.technicalReason != ""){
        reason += this.state.technicalReason + ","
      }

      if(this.state.otherReason != ""){
        reason += this.state.otherReason + ","
      }


     if(reason.endsWith(",")){
        reason = reason.substring(0 , reason.length - 1);
     }
       
    const body = {
      "serviceRatings":  this.state.rating,
      "comments": this.state.comments,
      "LeavingLobbyReason": reason
    };
    //alert("saveUserFeedback token >>"+this.common.token);
    
    const config = {
      headers: { 
        api_token: this.common.token
       }
    };
    console.log("save user feedback body > " + body);
    console.log("save user feedback config > " + config);
    
    
    const meetingID = this.queryParams.get("meetingID");
    
    const url = appconfig.azure_reports_url + '/api/customer-meeting/feedback/' + meetingID;
    console.log("save user feedback url > " + url);
    axios.post(url, body , config).then(response => {
      console.log("user feedback saved with status = "+response.status);
      let afterRatingUrl = window.location.href.replace("abnormalRating", "afterRating")
      window.location.href = afterRatingUrl+"&isPublic="+this.isPublic;
    });

  }
  displayReason = () =>{
    let other_reason = document.getElementById('other_reason') as HTMLInputElement;
    let otherReasonTextDiv = document.getElementById('otherReasonTextDiv') as HTMLElement;
    let otherReasonLabelDiv = document.getElementById('otherReasonLabelDiv') as HTMLElement;
    if(other_reason.checked) {
      otherReasonTextDiv.style.display = '';
      otherReasonLabelDiv.style.display = '';
    }else{
      otherReasonTextDiv.style.display = 'none';
      otherReasonLabelDiv.style.display = 'none';
    }
  }


  handleOtherReasonChange(event: any) {
    this.setState({
      otherReason: event.target.value
    })
  }

  handleWaitingReasonChange(event: any) {
    this.setState({
      waitingReason: event.target.value
    })
  }

  handleTechnicalReasonChange(event: any) {
    this.setState({
      technicalReason: event.target.value
    })
  }
 
  render() {
    let className = "";
    if(this.isPublic == "true"){
      className = "main-container";
    }else{
      className = "private-container";
    }
    return (
      <div id="main">
        <div className={className}>

          <div className="custom-top-card" data-i18n="rating_message">نأمل منك المشاركة فى هذا الاستبيان الذى يهدف إلى قياس رضاك والاستفادة من ملاحظاتك التى ستساهم بشكل فعّال فى فريق العمل لتحسين تجربتك</div>

          <br></br>
          <div className="row">
            <div className="col-lg-12" >
              <h5 className="t2" data-i18n="exit_reason"> فضلا اذكر سبب خروجك من الانتظار</h5>
            </div>
          </div>

        

          <div className="row">
            <div className="col-lg-12" >
              <input type="checkbox" value="وقت الانتظار طويل" name="long_wait_reason" id="long_wait_reason" onChange={ this.handleWaitingReasonChange}/>
              <span data-i18n="waiting_time_is_long">وقت الانتظار طويل</span>
            </div>
          </div>

          <br></br>
          <div className="row">
            <div className="col-lg-12" >
              <input type="checkbox" value="مشكلة تقنية" name="technical_reason" id="technical_reason" onChange={ this.handleTechnicalReasonChange}/>
              <span data-i18n="technical_error">مشكلة تقنية</span>
            </div>
          </div>

          <br></br>
          <div className="row">
            <div className="col-lg-12" >
              <input type="checkbox"  name="other_reason" id="other_reason" onClick={this.displayReason}/>
              <span data-i18n="other">أخرى</span>
            </div>
          </div>


          <br />
          <div className="row" id="otherReasonLabelDiv" style={{ display: 'none' }}>
            <div className="col-lg-6" style={{ textAlign: 'right' }}>
              <div data-i18n="unsatisfaction_reason">فضلا اذكر السبب  </div>
            </div>
          </div>
          <div className="row" id="otherReasonTextDiv" style={{ display: 'none' }}>
            <div className="col-lg-6" style={{ textAlign: 'center' }}>
              <textarea placeholder="اكتب هنا" className="md-textarea form-control" id="otherReasonText" cols={80} rows={2} onChange={this.handleOtherReasonChange}></textarea>
            </div>
          </div>
        <br></br>
          <div className="row">
            <div className="col-lg-4"><button className="btn btn-success" onClick={this.saveUserFeedback}><span data-i18n="save_btn">ارسال</span></button></div>
          </div>
          </div>

      </div>
    );
  }
}
 


const RatingWrapper = () => {
  return (
    <Provider store={store}> 
    <PersistGate persistor={persistedStore}>
      <AbnoramRating/>
      </PersistGate>
    </Provider>
  )
}
 

 
export default  RatingWrapper;
 

